.locationWrapper {
    display: flex;
    flex-direction: column;
}

.mapHeader {
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
}

.mapHeaderText {
    width: auto;
    margin: 0px;
    padding: 0px;
}