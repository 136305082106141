#Feedback {
  background: white;
  border: 1px solid #ccc;
  min-height: 10rem;
  padding: 0.5rem;
  margin: 1rem auto;
}

div.feedback {
  background: #f8f8f8;
  min-height: calc(100vh - 100px);
  font-size: 90%;
  border: 1px solid #ccc;
  margin-top: 1rem;
  padding: 2rem;
  margin: 1rem 5rem;
  color: #333;

  @media screen and (max-width: 820px) {
    width: 100vw;
    left: 0px;
    right: 0px;
    top: 150px;
    position: absolute;
    margin: 0px;
  }

  @media screen and (max-width: 768px) {
    top: 100px;
  }
}

div.feedback > * {
  margin: auto;
  max-width: 40rem;
}

#name,
#email {
  width: 30rem;
  border: 1px solid #ccc;
  padding: 0.5rem;
  background: white !important;

  @media screen and (max-width: 820px) {
    width: calc(100vw * 0.75);
  }
}