#Advanced {
  margin-bottom: 5rem;
  background: #eee;
  text-align: center;
  padding: 1rem;

  @media screen and (max-width: 768px) {
    position: absolute;
    top: 100px;
    right: 0px;
    left: 0px;
    padding: 0px;
  }
}

.advanced {
  display: inline-block;
  margin: 0 0.5rem;
  border: 1px solid #aaa;
  position: relative;

  @media screen and (max-width: 768px) {
    margin: 0px;
  }
}