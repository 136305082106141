.Init {
  .fields {
    position: absolute;
    right: 10px;
    top: 2rem;
    width: 10em;
  }
}

.desktop {
  display: flex;
}

.mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
  }

  .Init {
    .fields {
      position: relative;
      right: 0px;
      top: 0px;
    }
  }
}